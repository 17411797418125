.qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
}
.qrcode img {
  width: 400px;
  height: 400px;
  background-color: #999;
}
.searchBox .el-icon-arrow-down:before {
  content: "\e6df" !important;
}
